import * as React from 'react'
import { navigate } from '@reach/router'
import QRCode from 'react-qr-code'
import './fullCoverLoader.less'
import Loader from './loader'
import Button from 'components/button/Button'

interface Props {
  onCancel?: () => void
  setShowLoader: (value: boolean) => void
  setShowOverlay: (value: boolean) => void
  showLoader: boolean
  showOverlay: boolean
  showQrCode?: boolean
  subtitle?: React.ReactNode
  value: string
}

const FullCoverLoader = ({
  onCancel,
  setShowOverlay,
  showLoader = true,
  showOverlay,
  showQrCode,
  subtitle,
  value,
  ...props
}: Props) => {
  const cancelBankIdProcess = () => {
    onCancel && onCancel()
    navigate('/logga-in')
    setShowOverlay(false)
  }

  return (
    <>
      {showOverlay && (
        <div className="loaderContainer">
          {showLoader && <Loader color="dark" fade={true} />}
          <div className={`loaderText${!showLoader ? ' tight' : ''}`}>
            {subtitle && <p>{subtitle}</p>}

            <div>{showQrCode && <QRCode value={value} size={200} />}</div>

            {onCancel && (
              <Button
                className="button link-blue cancelButton"
                onClick={() => cancelBankIdProcess()}
              >
                Avbryt
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FullCoverLoader
