import OnboardingLayout from '../../layout/OnboardingLayout'
import SEO from '../../components/seo'
import './sign-in.less'

import bubbles from '../../images/circles_login.svg'
import Auth from 'components/signin/auth'
import { pushPageViewPreventDuplicates } from '../../utils/gtmUtils'
import React from 'react'

const SignInTemplate = () => {
  React.useEffect(() => {
    pushPageViewPreventDuplicates()
  }, [])

  return (
    <OnboardingLayout>
      <SEO description="" title="Logga in" />
      <div className="sign-in" style={{ height: '100vh', backgroundImage: `url(${bubbles})` }}>
        <Auth />
      </div>
    </OnboardingLayout>
  )
}

export default SignInTemplate
