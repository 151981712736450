import * as React from 'react'
import './signInForm.less'
import { authDevices } from 'components/signin/auth'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { useIntl } from 'react-intl'

interface Props {
  onInitiateAuth: (sameDevice: boolean, identityNumber: { value: string; isValid: boolean }) => void
  currentValue: string
  setShowLoader: (value: boolean) => void
  setShowOverlay: (value: boolean) => void
}

type IntlProps = Props & WrappedComponentProps<'intl'>

const SignInForm: React.FC<IntlProps> = (props) => {
  const { formatMessage } = useIntl()

  const { onInitiateAuth, setShowLoader, setShowOverlay } = props

  const handleAuthInitiation = (withQrCode: boolean) => {
    setShowOverlay(true)
    setShowLoader(withQrCode)
    onInitiateAuth(withQrCode, { value: '', isValid: true })
  }

  const buttonOptions = [
    {
      name: authDevices.SAME_DEVICE,
      id: 'same',
    },
    {
      name: authDevices.OTHER_DEVICE,
      id: 'other',
    },
  ] as const

  const onClick = (buttonId: 'same' | 'other') => {
    if (buttonId === 'other') {
      handleAuthInitiation(false)
    } else {
      handleAuthInitiation(true)
    }
  }

  const createAuthButtonRender = () => (btn: { name: string; id: 'same' | 'other' }) => {
    const className = `button button--normal orange u-sizeFull u-marginBmd`
    const localeId = `buttons.${btn.name}`

    return (
      <button
        onClick={() => onClick(btn.id)}
        key={btn.id}
        name={btn.name}
        type="submit"
        className={className}
      >
        {formatMessage({ id: localeId })}
      </button>
    )
  }

  return (
    <div className="signIn__elements">
      <div className="u-marginVmd">{buttonOptions.map(createAuthButtonRender())}</div>
    </div>
  )
}

export default injectIntl(SignInForm)
