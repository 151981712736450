import React from "react";

import { IntlProvider } from "react-intl";
import locale from "../../data/onboarding.json";
import { flattenObject } from "../../utils/tools";
import SignIn from "components/signin/SignIn";

export enum authDevices {
  SAME_DEVICE = "Logga in med BankID på denna enhet",
  OTHER_DEVICE = "Logga in med BankID på annan enhet"
}

const flattedLocale = flattenObject(locale);

interface Props {}

const Auth = (props: Props) => {
  return (
    <IntlProvider locale="en" messages={flattedLocale}>
      <SignIn path={`logga-in`} />
    </IntlProvider>
  );
};

export default Auth;
