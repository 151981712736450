import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../store'
import { injectIntl } from 'react-intl'
import * as Redux from 'redux'
import SignInForm from 'components/signin/signInForm'
import {
  initiateBankIdAuth,
  fetchAuthStatus,
  cancelBankIdProcess,
  authStatusUpdated,
} from 'components/onboarding/bankID/actions'
import { AuthActionTypes, AuthState } from 'store/auth/types'
import { navigate } from '@reach/router'
import FullCoverLoader from 'components/loader/FullCoverLoader'
import {
  BankIdError,
  MemberStatuses,
  MemberRoles,
  BankIdActionTypes,
} from 'components/onboarding/bankID/types'
import Button from 'components/button/Button'

interface StateProps {
  auth: AuthState
  autoLaunch?: boolean
  endpoint?: string
  error?: BankIdError
  hasError?: boolean
  isCancelled?: boolean
  isComplete?: boolean
  launchUrl?: string
  memberStatus?: MemberStatuses
  orderRef?: string
  qrCode?: string
  retrySeconds?: number
  role?: MemberRoles
  startFailed?: boolean
  statusMessage?: string
}

interface DispatchProps {
  getAuthDetails: () => void
  initiateAuth: (payload: object) => void
  authStatusUpdated: (payload: object) => void
  cancelAuth: (orderRef: string) => void
  discardError: () => void
  fetchAuthStatus: (
    orderRef: string,
    endpoint: string,
    isSwedishPersonalIdentityNumberProvided: boolean,
    autoLaunch: boolean,
    cancelPolling?: boolean,
  ) => void
}

type Props = DispatchProps &
  StateProps &
  ReactIntl.InjectedIntlProps & {
    intl: { formatMessage: (id: string) => string }
  }

interface State {
  autoLaunch: boolean
  hasError: boolean
  initializingAuth: boolean
  isCancelled: boolean
  isComplete: boolean
  showLoader: boolean
  showOverlay: boolean
  showResultStatus: boolean
  startFailed: boolean
  timesInitialized: number
}

class SignIn extends React.PureComponent<Props, State> {
  state = {
    autoLaunch: false,
    hasError: false,
    initializingAuth: false,
    isCancelled: false,
    isComplete: false,
    showLoader: false,
    showOverlay: false,
    showResultStatus: false,
    startFailed: false,
    timesInitialized: 0,
  }

  memberStatusTimeout: number | undefined

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps === this.props) {
      return
    }

    if (this.props.auth.status === 'LOGGED_IN') {
      navigate('/mina-sidor')
      return
    }
    
    if (!prevProps.isComplete && this.props.isComplete) {
      this.onSuccessfulAuth()
    }

    if (!prevProps.startFailed && this.props.startFailed) {
      if (this.state.timesInitialized < 5) {
        this.setState((prevState) => ({
          timesInitialized: prevState.timesInitialized + 1,
        }))

        this.onInitiateAuth(this.state.autoLaunch)
      } else {
        console.error('Failed to start session 5 times in a row. Please try again later.')
      }
    }

    if (this.props.orderRef != null && prevProps.orderRef !== this.props.orderRef) {
      this.initiateAuthPolling(this.state.autoLaunch)
    }
    //Only show member status when this changes from loaded page.
    if (prevProps.memberStatus !== this.props.memberStatus) {
      this.setState({ showResultStatus: true })
    }
    if (this.props.launchUrl && this.props.launchUrl !== prevProps.launchUrl) {
      window.location.href = this.props.launchUrl
    }
    if (this.state.initializingAuth && (this.props.orderRef || this.props.error)) {
      this.setState({ initializingAuth: false })
    }
  }

  initiateAuthPolling = (autoLaunch: boolean) => {
    if (!this.props.orderRef || !this.props.endpoint) {
      return null
    }
    this.props.fetchAuthStatus(this.props.orderRef, this.props.endpoint, false, autoLaunch) //Här ska vi skicka med om autoLaunch eller inte. Dvs om det är local inloggning eller remote.
  }

  cancelAuth = () => {
    if (!this.props.orderRef) {
      return null
    }
    this.props.cancelAuth(this.props.orderRef)
  }

  onSuccessfulAuth = () => {
    const { memberStatus } = this.props
    if (memberStatus === 'Member' || memberStatus === 'Entering' || memberStatus === 'Exiting') {
      console.log('onSuccessfulAuth -> getAuthDetails');
      this.props.getAuthDetails()
    }
  }

  onInitiateAuth = (autoLaunch: boolean) => {
    this.setState({ initializingAuth: true })
    this.setState({ autoLaunch: autoLaunch })
    this.props.initiateAuth({
      autoLaunch: autoLaunch,
    })
  }

  renderAuthWarning = () => {
    const { memberStatus } = this.props
    if (!memberStatus) {
      return null
    }

    const BackButton = () => (
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => this.setState({ showResultStatus: false })}
        className="Link u-textSmall u-marginTmd"
      >
        Tillbaka
      </a>
    )

    switch (memberStatus) {
      case 'NotMember':
        return (
          <div className="authWarning">
            <h4>Du har inget gällande medlemskap för tillfället.</h4>
            <Button
              className="button button--normal blue u-marginTlg"
              onClick={() => navigate('/bli-medlem')}
            >
              Bli medlem
            </Button>
            <BackButton />
          </div>
        )
      // case "Entering":
      //   return (
      //     <div className="authWarning">
      //       <h4>
      //         Ditt medlemskap har inte börjat gälla ännu. Prova igen senare.
      //       </h4>
      //       <BackButton />
      //     </div>
      //   );
      case 'Exiting':
      case 'Entering':
      case 'Member':
        return (
          <div className="authWarning">
            <h4>BankID-identifiering är mottagen, du skickas strax vidare.</h4>
          </div>
        )
      default:
        return null
    }
  }

  render() {
    const { error, statusMessage, qrCode, intl, orderRef } = this.props
    const { formatMessage } = intl

    const loaderText =
      error != null
        ? error.displayMessage || (
            <>
              Någonting gick fel. Avbryt och försök igen.
              <br />
              BankID går att testa på{' '}
              <a className="Link" href="https://test.bankid.com" target="_blank">
                https://test.bankid.com
              </a>
            </>
          )
        : statusMessage

    return (
      <>
        <div className="sign-in--box">
          <h4 className="u-paddingTlg">{formatMessage({ id: `views.sign-in.heading` })}</h4>

          <SignInForm
            onInitiateAuth={this.onInitiateAuth}
            currentValue={''}
            setShowLoader={(value) => this.setState({ showLoader: value })}
            setShowOverlay={(value) => this.setState({ showOverlay: value })}
          />

          {(this.props.orderRef || this.props.error || this.state.initializingAuth) && (
            <FullCoverLoader
              showOverlay={this.state.showOverlay}
              showLoader={this.state.showLoader}
              setShowLoader={(value) => this.setState({ showLoader: value })}
              setShowOverlay={(value) => this.setState({ showOverlay: value })}
              onCancel={error != null ? this.props.discardError : this.cancelAuth}
              subtitle={loaderText}
              showQrCode={qrCode && !error}
              value={`${qrCode}`}
            />
          )}

          {this.state.showResultStatus && this.renderAuthWarning()}
        </div>
      </>
    )
  }
}
const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  getAuthDetails: () => dispatch({ type: AuthActionTypes.UPDATE_AUTH }),
  initiateAuth: (payload: object) => dispatch(initiateBankIdAuth(payload)),
  cancelAuth: (orderRef: string) => dispatch(cancelBankIdProcess(orderRef)),
  authStatusUpdated: (payload: object) => dispatch(authStatusUpdated(payload)),
  discardError: () => dispatch({ type: BankIdActionTypes.DISCARD_ERROR }),
  fetchAuthStatus: (
    orderRef: string,
    endpoint: string,
    isSwedishPersonalIdentityNumberProvided: boolean,
    autoLaunch: boolean,
    cancelPolling?: boolean,
  ) =>
    dispatch(
      fetchAuthStatus(
        orderRef,
        endpoint,
        isSwedishPersonalIdentityNumberProvided,
        autoLaunch,
        cancelPolling,
      ),
    ),
})
const mapStateToProps = (state: ApplicationState): StateProps => ({
  auth: state.auth,
  endpoint: state.bankId.endpoint,
  orderRef: state.bankId.orderRef,
  autoLaunch: state.bankId.autoLaunch,
  launchUrl: state.bankId.launchUrl,
  hasError: state.bankId.hasError,
  isComplete: state.bankId.isCompleted,
  qrCode: state.bankId.qrCode,
  isCancelled: state.bankId.isCancelled,
  memberStatus: state.bankId.memberStatus,
  statusMessage: state.bankId.statusMessage,
  role: state.bankId.role,
  retrySeconds: state.bankId.retrySeconds,
  error: state.bankId.error,
  startFailed: state.bankId.startFailed,
})

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SignIn))
